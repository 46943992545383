import base64url from 'base64url';

export const redirectWithOriginUrl = (router, to, params) => {
  const { asPath, query } = router;
  const originUrl = `${asPath}`;
  const encodedOriginUrl = base64url.encode(originUrl);

  const queryParams = params ? { ...params, encodedOriginUrl } : { encodedOriginUrl };

  return router.replace(`${to}?${new URLSearchParams(queryParams).toString()}`);
};

export const handleBack = (router) => {
  const { encodedOriginUrl } = router.query;
  if (encodedOriginUrl) {
    const urlFrom = base64url.decode(encodedOriginUrl);
    if (typeof urlFrom === 'string') {
      router.push(urlFrom);
    }
  }
  return router.back();
};

export const redirectToEncodedOriginUrl = (router, fallback = null) => {
  const { encodedOriginUrl } = router.query;
  if (encodedOriginUrl) {
    const url = base64url.decode(encodedOriginUrl);
    if (typeof url === 'string') {
      window.location.replace(url);
      return null;
    }
  }

  if (fallback) {
    return fallback();
  }

  window.location.replace('/');
  return null;
};
