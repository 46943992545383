import { ENV } from 'constants/index';

export const { GA_TRACKING_ID } = ENV;
export const { GTAG_PURCHASING_ID } = ENV;
export const { GTM_TRACKING_ID } = ENV;

// action item here
export const GTAG_EVENT_ACTION = {
  // //  Register
  // register: 'register',

  // // Add to cart
  add_to_cart: 'add_to_cart',
  // // Remove from cart
  remove_from_cart: 'remove_from_cart',
  // Purchase
  purchase: 'purchase',
  // // Begin checkout
  begin_checkout: 'begin_checkout',
  // // Revenue
  // revenue: 'revenue',
  // // Add payment info (metode pembayaran)
  add_payment_info: 'add_payment_info',
  // // Add shipping info (pilih alamat pengiriman)
  add_shipping_info: 'add_shipping_info',
  // // Add logistic info (pilih model pengiriman)
  add_logistic_info: 'add_logistic_info',
  // // Promotions
  // promotions: 'promotions',
  // // Select item
  select_item: 'select_item',
  // // Select promotions
  // select_promotions: 'select_promotions',
  // // View item details
  view_item_details: 'view_item_details',
  view_item: 'view_item',
  // // View item list
  // view_item_list: 'view_item_list',
  // // Facebook pixel
  // facebook_pixel: 'facebook_pixel',
  view_promotion: 'view_promotion',
  share: 'share',
  search: 'search',
  register_submit_b2b: 'register_submit_b2b',
  register_submit_retail: 'register_submit_retail',
  register_submit_seller: 'register_submit_seller',
  registered_b2b: 'registered_b2b',
  registered_retail: 'registered_retail',
  registered_seller: 'registered_seller',
  login: 'login',
};

export const GTAG_CONTENT_TYPES = {
  product: 'product',
  image: 'image',
  article: 'article',
  page: 'page',
  hero_banner: 'hero_banner',
};

export const GTAG_SHARE_METHODS = {
  twitter: 'Twitter',
  whatsapp: 'Whatsapp',
  facebook: 'Facebook',
  link: 'Link',
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const gtagPageView = (url) => {
  if (typeof window !== 'undefined') {
    window.gtag('config', GA_TRACKING_ID, {
      page_path: url,
    });
  }
};

export const gtagSetUserId = (user_id) => {
  if (typeof window !== 'undefined') {
    window.gtag('config', GA_TRACKING_ID, {
      user_id: String(user_id),
    });
  }
};

export const gtagEvent = (action, payload = {}) => {
  if (typeof window !== 'undefined') {
    window.gtag('event', action, payload);
  }
};

// // https://developers.google.com/analytics/devguides/collection/gtagjs/events
// export const gtagEvent = ({ action, category, label, value }) => {
//   window.gtag('event', action, {
//     event_category: category,
//     event_label: label,
//     value: value,
//   });
// };

export const getItemDataFromProductDetail = (productDetail, index = 0) => {
  const { price, productId, id, title, brand, categoryName } = productDetail;
  return {
    item_id: String(id),
    item_name: title,
    index,
    item_brand: brand,
    item_category: categoryName,
    item_list_id: productId,
    item_list_name: title,
    price,
    quantity: 1,
  };
};

export const getItemDataFromProductReviewItem = (productReviewItem, index = 0) => {
  const { productId, productName, brand, qty, finalPrice } = productReviewItem;

  return {
    item_id: String(productId),
    item_name: productName,
    index,
    item_brand: brand,
    item_list_name: productName,
    price: finalPrice,
    quantity: qty,
  };
};

export const getItemDataFromRetailPurchaseProduct = (retailPurchaseProduct, index = 0) => {
  const {
    product: { id: productId, title, brand },
    productPrice,
    quantity,
  } = retailPurchaseProduct;

  return {
    item_id: String(productId),
    item_name: title,
    index,
    item_brand: brand,
    item_list_name: title,
    price: productPrice,
    quantity,
  };
};
