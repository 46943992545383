import request from 'utils/request.utils';
import { parseCookies } from 'nookies';

const getUser = async () => {
  const cookies = parseCookies();
  try {
    const response = await request({
      withAuth: true,
      tokenAuth: cookies.token,
    }).get(`/user/detail`);
    if (response.status === 200) {
      return response.data;
    } else {
      throw response;
    }
  } catch (e) {
    throw e.message;
  }
};

const activationMail = async (payload) => {
  const cookies = parseCookies();
  try {
    const response = await request({
      withAuth: true,
      tokenAuth: cookies.token,
    }).post(`/user/activation-mail`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      throw response;
    }
  } catch (e) {
    throw e.message;
  }
};

const postChangePassword = async (payload) => {
  const cookies = parseCookies();
  try {
    const response = await request({
      withAuth: true,
      tokenAuth: cookies.token,
    }).post(`/user/change-password`, null, { params: payload });
    if (response.status === 200) {
      return response.data;
    } else {
      throw response;
    }
  } catch (e) {
    throw e.response?.data;
  }
};

const putProfile = async (payload) => {
  const cookies = parseCookies();
  try {
    const response = await request({
      withAuth: true,
      tokenAuth: cookies.token,
    }).put(`/user/edit`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      throw response;
    }
  } catch (e) {
    throw e.response?.data;
  }
};

const user = {
  getUser,
  activationMail,
  postChangePassword,
  putProfile,
};

export default user;
