import request from 'utils/request.utils';
import { parseCookies } from 'nookies';
import { PATHNAME_RECOVERY_PASSWORD, ENV } from 'constants/index';

const login = async ({ username, password }) => {
  try {
    const response = await request({ withAuth: false }).post('/auth/login', { username, password });
    if (response.status === 200) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response;
  }
};

const logout = async (username) => {
  const cookies = parseCookies();

  try {
    const response = await request({
      withAuth: true,
      tokenAuth: cookies.token,
    }).post('/user/logout', null, { params: { username } });
    if (response.status === 200) {
      return response.data;
    }
    throw response;
  } catch (e) {
    console.error(e);
    throw e.message;
  }
};

const forgotPassword = async (email) => {
  try {
    const response = await request({ withAuth: false }).post(
      'auth/forgot-password/send-mail',
      null,
      {
        params: {
          email,
          redirectUrl: ENV.BASE_URL + PATHNAME_RECOVERY_PASSWORD,
          roleGroup: 'BUYER',
        },
      },
    );
    if (response.status === 200) {
      return response.data;
    }
    throw response;
  } catch (e) {
    throw e.response.data;
  }
};

const newPassword = async ({ password, rePassword, token }) => {
  try {
    const encodeToken = encodeURIComponent(token).replace('%20', '+');
    const response = await request({ withAuth: false }).post(
      `auth/forgot-password/${encodeToken}`,
      null,
      { params: { password, rePassword } },
    );
    if (response.status === 200) {
      return response.data;
    }
    throw response;
  } catch (e) {
    throw e.response.data;
  }
};

const activation = async ({ password, rePassword, token }) => {
  try {
    const encodeToken = encodeURIComponent(token).replace('%20', '+');
    const response = await request({ withAuth: false }).post('auth/activation', {
      token: encodeToken,
      password,
      rePassword,
    });
    if (response.status === 200) {
      return response.data;
    }
    throw response;
  } catch (e) {
    throw e.response.data;
  }
};

const activationCustomer = async ({ token }) => {
  try {
    const encodeToken = encodeURIComponent(token).replace('%20', '+');
    const response = await request({ withAuth: false }).post(
      `auth/activation/${encodeToken}/customer`,
      null,
    );
    if (response.status === 200) {
      return response.data;
    }
    throw response;
  } catch (e) {
    throw e.response.data;
  }
};

const activationCheckToken = async (payload) => {
  try {
    const encodeToken = encodeURIComponent(payload?.token).replace('%20', '+');
    const response = await request({ withAuth: false }).post('auth/activation/check-token', {
      token: encodeToken,
    });

    if (response.status === 200) {
      return response.data;
    }
    throw response;
  } catch (e) {
    throw e.response.data;
  }
};

const auth = {
  login,
  logout,
  forgotPassword,
  newPassword,
  activation,
  activationCustomer,
  activationCheckToken,
};

export default auth;
