import { CONFIG_SITE } from '../../global-config';

export const SEO = {
  title: CONFIG_SITE?.SEO?.MAINSITE?.TITLE || '',
  description: 'eProcurement system',
  canonical: CONFIG_SITE?.CONTENT?.COMPANY?.WEBSITE,
  openGraph: {
    url: CONFIG_SITE?.CONTENT?.COMPANY?.WEBSITE,
    title: `eProcurement | ${CONFIG_SITE?.CONTENT?.BRAND}`,
    description: `eProcurement, ${CONFIG_SITE?.CONTENT?.BRAND}, telkom`,
    images: [
      {
        url: CONFIG_SITE?.OPEN_GRAPH?.IMAGE,
        width: 1200,
        height: 630,
        alt: `eProcurement | ${CONFIG_SITE?.CONTENT?.BRAND}`,
      },
    ],
    site_name: `eProcurement | ${CONFIG_SITE?.CONTENT?.BRAND}`,
  },
  twitter: { ...(CONFIG_SITE.OPEN_GRAPH.TWITTER || {}) },
};

export default {};
