const BUYING_OPTIONS = require('./global-config-variables/buying-options');
const WHITELABEL_KEYS = require('./global-config-variables/whitelabel-keys');
const PAYMENT_METHOD_KEYS = require('./global-config-variables/payment-method-keys');

module.exports = {
  CONFIG_SITE: {
    BUYING_TYPE: BUYING_OPTIONS.purchase,
    WHITELABEL_KEY: WHITELABEL_KEYS.xooply,
    LOGO_XOOPLY_WITH_METRANET: 'https://storage.googleapis.com/xooply-assets/logo-xooply-by-metranet.png',
    AUTH_BG_URL: 'https://storage.googleapis.com/xooply-assets/login.png',
    LOGO_URL: 'https://storage.googleapis.com/xooply-assets/logo_MztI84tZQ.svg',
    LOGO_MOBILE_URL: 'https://storage.googleapis.com/xooply-assets/logo-mobile_TEefbcxSr.svg',
    ICO_URL: 'https://storage.googleapis.com/xooply-assets/xooply.ico',
    CATEGORY_POPOVER_IMAGE_URL:
      'https://storage.googleapis.com/xooply-assets/category-popover-image.jpg',
    PRODUCT_CARD_WATERMARK_URL: '',
    REVISED_WATERMARK_URL: 'https://storage.googleapis.com/xooply-assets/revised-watermark.png',
    DOCUMENT_LOGO_PATH: '/assets/icons/xooply-logo.svg',
    PAID_WATERMARK_IMAGE_URL: 'https://storage.googleapis.com/xooply-assets/paid-watermark-xooply.png',
    IS_SHOW: {
      REGISTER_RETAIL: false,
      TRACKING_ORDER: true,
      STORE_NAME_RETAIL: true,
    },
    ADMIN: {},
    MAINSITE: {
       HOMEPAGE_SEO_TEXT: true,
    },
    SELLER: {
      HIDE_PICKUP_COURIER: false,
      DASHBOARD_BANNER: {
        IS_SHOW: false,
        IMAGES: 'https://storage.googleapis.com/xooply-assets/idfood/main-banner-panduan-penjual.jpg',
        CALLBACK_URL: 'https://storage.googleapis.com/xooply-assets/idfood/documents/IDFOOD_Seller_Feature.pdf',
        IS_OPEN_NEW_TAB: true
      },
    },
    EMETERAI_PRODUCTID: '95522',
    APPLY_REVAMPED_TAX: true,
    APPLY_DECIMAL_HANDLER: true,
    ALLOWED_PAYMENT_METHOD_TYPES: [],
    ENABLE_OPEN_MARKET: true,
    MAX_BUYER_STAFF_AMOUNT: 20,
    REWARD_PROGRAM: true,
    REWARD_MANAGER_CAN_JOIN: false,
    BANNER_MANAGEMENT: true,
    ARTICLES_MANAGEMENT: true,
    PRODUCT_COLLECTION_MANAGEMENT: true,
    AMPDD: true,

    CONTENT: {
      BRAND: 'XooplyID',
      DOMAIN: 'Xooply.id',
      COMPANY: {
        NAME: 'PT. Metra-Net',
        BRAND: 'Xooply',
        DOMAIN: 'Xooply.id',
        WEBSITE: 'https://xooply.id',
      },
      ADDRESS_LIST: [
        'PT. Metra-Net Jakarta',
        'Mulia Business Park , Building J',
        'Jln. MT Haryono Kav 58-60 Jakarta Selatan',
        '12780',
      ],
      DOCUMENT_ADDRESS_LIST: [
        'PT Metranet',
        'Jl Letjen MT Haryono Kav 58 - 60,',
        'Pancoran, Jakarta Selatan, 12780',
        'No. HP: (021) 79187260',
        'No. NPWP: 02.978.514.4-093.000',
      ],
      CONTACTS: {
        WHATSAPP: {
          NUMBER: '081119621812',
          LINK: 'https://wa.me/6281119621812',
        },
        TELEGRAM: {
          USERNAME: '@HD_Bussop',
          LINK: 'https://t.me/HD_Bussop',
        },
        EMAIL: {
          SUPPORT: 'corporate.support@metranet.co.id',
        },
	PHONE: {
         NUMBER: '+6281119621812',
	},
	EMBED_GOOGLE_MAP: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.138395759749!2d106.84405215079683!3d-6.245486295457065!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f3b9001470bf%3A0x6ba8b244e28bc14!2sPT%20METRA-NET!5e0!3m2!1sen!2sid!4v1679718380790!5m2!1sen!2sid" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',
      },
      DOCUMENT_PRIVACY_POLICY_URL: 'https://storage.googleapis.com/xooply-assets/markdown-content/privacy-policy.md',
      DOCUMENT_PANDUAN_PEMBELI_URL:
        'https://xooply.id/artikel/panduan/pembeli',
      DOCUMENT_PANDUAN_PENJUAL_URL:
        'https://xooply.id/artikel/panduan/penyedia',
      DOCUMENT_TERMS_AND_CONDITIONS_URL:
        'https://storage.googleapis.com/xooply-assets/markdown-content/terms-and-conditions.md',
      FOOTER: {
        SUMMARY: `Platform B2B e-commerce produk dari PT Metra-Net (Metranet). Platform yang mengusung tagline “Your Business Supply Solution” menyediakan kebutuhan bisnis seperti stationary, consumer goods, electronic, custom product dan berbagai produk-produk Maintenance, Repairing & Operation (MRO) serta layanan lainnya.`,
        JOINED_COMPANY_LOGO_URLS: [
          'https://storage.googleapis.com/xooply-assets-public/company/company-aken.png',
          'https://storage.googleapis.com/xooply-assets-public/company/company-idea.png',
          'https://storage.googleapis.com/xooply-assets-public/company/company-lkpp.png',
        ],
        SUPPORTED_PAYMENT_LOGO_URLS: [
          'https://storage.googleapis.com/xooply-assets-public/payment-bni.png',
          'https://storage.googleapis.com/xooply-assets-public/payment-bri.png',
          'https://storage.googleapis.com/xooply-assets-public/payment-mandiri.png',
	  'https://storage.googleapis.com/xooply-assets-public/credit-card.png',
        ],
        SUPPORTED_LOGISTIC_LOGO_URLS: [
          'https://storage.googleapis.com/xooply-assets-public/courier-logo/delivery-jne.png',
          'https://storage.googleapis.com/xooply-assets-public/courier-logo/delivery-jnt.png',
          'https://storage.googleapis.com/xooply-assets-public/courier-logo/delivery-paxel.png',
          'https://storage.googleapis.com/xooply-assets-public/courier-logo/delivery-deliveree.png',
          'https://storage.googleapis.com/xooply-assets-public/courier-logo/delivery-gojek.png',
          'https://storage.googleapis.com/xooply-assets-public/courier-logo/delivery-grab.png',
        ],
	STANDARDS_AND_CERTIFICATIONS: [
          'https://storage.googleapis.com/xooply-assets/iso27001.png',
        ],
      },
      SOCIAL_MEDIA_LINKS: [
       {
         name: 'facebook',
         link: 'https://m.facebook.com/xooply',
         username: '',
       },
       {
         name: 'instagram',
         link: 'https://instagram.com/xooply.id?igshid=MzRlODBiNWFlZA==',
         username: '',
       },
       {
         name: 'linkedin',
         link: 'https://www.linkedin.com/company/xooply',
         username: '',
       },
       {
         name: 'tiktok',
         link: 'https://www.tiktok.com/@xooply.id?_t=8gZxtIZ3jHN&_r=1',
         username: '',
       },
       {
         name: 'twitterx',
         link: 'https://x.com/xooply_id?s=21&t=kWnyS2RjyhAHPUcsfkVDfQ',
         username: '',
       },
       {
         name: 'youtube',
         link: 'https://youtube.com/@xooply_id?si=m7nUCgHm7KNgEGAX',
         username: '',
       },
      ],
      PAGE: {
        MAINSITE: {
          HOMEPAGE: {
           popup_banner: {
              name: 'popup_banner',
              title: 'popup_banner',
              content: {
                image: {
                  path: 'https://storage.googleapis.com/xooply-assets/banner-homepage/popup-banner.png',
                },
                redirectUrl: '/',
                sortIndex: 1,
              },
            },
	    hero_banner: {
              name: 'Main Banners',
              title: 'Main Banners',
              content: [
                {
                  image: {
                    path: 'https://storage.googleapis.com/xooply-assets/banner-hot-product.png',
                    seo: {
                      alt: 'Xooply your business solution',
                      desc: 'Xooply your business solution',
                    },
                  },
                  redirectUrl: '/cp/hot-product',
                  sortIndex: 0,
                },
                {
                  type: 'LARGE',
                  image: {
                    path: 'https://storage.googleapis.com/xooply-assets/banner-register.jpg',
                    seo: {
                      alt: 'bergabung xooply dan temukan semua kemudahan berbelanja',
                      desc: 'bergabung xooply dan temukan semua kemudahan berbelanja',
                    },
                  },
                  redirectUrl: '/register',
                  sortIndex: 1,
                },
                {
                  type: 'LARGE',
                  image: {
                    path: 'https://storage.googleapis.com/xooply-assets/banner-rfq.jpg',
                    seo: {
                      alt: 'Xooply your business solution',
                      desc: 'Xooply your business solution',
                    },
                  },
                  redirectUrl: '/cp/rfq',
                  sortIndex: 2,
                },
              ],
            },
            recommendation_banner: {
              name: 'recommendation banners',
              title: 'Kebutuhan Bisnis Anda',
              content: [
                {
                  image: {
                    path: 'https://storage.googleapis.com/xooply-assets/banner-homepage/mini-banner/mini-banner-1.jpg',
                    seo: {
                      alt: 'Katalog Barang Dan Jasa Kantor Terlengkap',
                      desc: 'Katalog Barang Dan Jasa Kantor Terlengkap',
                    },
                  },
                  redirectUrl: '/cp/koleksi/katalog-barang-dan-jasa-kantor-terlengkap',
                  sortIndex: 0,
                },
                {
                  image: {
                    path: 'https://storage.googleapis.com/download/storage/v1/b/xooply-static-production/o/dev%2Fmini-banner-kebutuhan-sekolah.png-hvEuW9.png?generation=1730391601197924&alt=media',
                    seo: {
                      alt: 'Koleksi Kebutuhan Sekolah',
                      desc: 'Koleksi Kebutuhan Sekolah',
                    },
                  },
                  redirectUrl: '/cp/koleksi/kebutuhan-sekolah',
                  sortIndex: 1,
                },
                {
                  image: {
                    path: 'https://storage.googleapis.com/xooply-assets/banner-homepage/mini-banner/mini-banner-3.jpg',
                    seo: {
                      alt: 'Katalog Produk Universitas',
                      desc: 'Katalog Produk Universitas',
                    },
                  },
                  redirectUrl: '/cp/koleksi/katalog-produk-universitas',
                  sortIndex: 2,
                },
                {
                  image: {
                    path: 'https://storage.googleapis.com/xooply-assets/banner-homepage/mini-banner/mini-banner-4.jpg',
                    seo: {
                      alt: 'Panduan Cara Bayar',
                      desc: 'Panduan Cara Bayar',
                    },
                  },
                  redirectUrl: '/panduan/cara-bayar',
                  sortIndex: 3,
                },
              ],
            },
            middle_banner: {
              name: 'middle_banner',
              title: 'middle_banner',
              content: {
                image: {
                  path: 'https://storage.googleapis.com/xooply-assets/middle-banner-rfq.jpg',
                  seo: {
                    alt: 'Xooply your business solution',
                    desc: 'Xooply your business solution',
                  },
                },
                redirectUrl: '/cp/rfq',
                sortIndex: 1,
              },
            },
            highlighted_products: [
              {
                name: 'Office Supplies',
                title: 'Office Supplies',
                categoryId: '1',
		categoryCode: 'alat-tulis-kantor',      
		content: {
                  redirectUrl: null,
                  banner: {
                    image: {
                      path: 'https://storage.googleapis.com/xooply-assets/highlighted-product-kantor.jpg',
                      seo: {
                        alt: 'Groceries',
                        desc: 'Groceries',
                      },
                    },
                    redirectUrl: '/',
                    sortIndex: 2,
                  },
                  products: [],
                },
              },
              {
                name: 'Komputer & Elektronik',
                title: 'Komputer & Elektronik',
                categoryId: '4',
		categoryCode: 'peralatan-elektronik',
	        content: {
                  redirectUrl: '/',
                  banner: {
                    image: {
                      path: 'https://storage.googleapis.com/xooply-assets/highlighted-product-gadget.jpg',
                      seo: {
                        alt: 'Komputer & Elektronik',
                        desc: 'Komputer & Elektronik',
                      },
                    },
                    redirectUrl: '/',
                    sortIndex: 1,
                  },
                  products: [],
                },
              },
            ],
            buyer_testimonial: [
              {
                name: 'Azalika Andjani',
                occupation: 'Karyawan Swasta',
                rate: 4.5,
                review:
                  'Xooply memberikan pengalaman berbelanja yang seamless, karena UI dan UX mudah dipahami.',
              },
              {
                name: 'Fatharany Aqni',
                occupation: 'Karyawan Swasta',
                rate: 4.5,
                review:
                  'Desain antarmuka yang sederhana dan kemudahan navigasi menurutku harus tetap dipertahankan.',
              },
              {
                name: 'Renata Adelhia Setiawan',
                occupation: 'Karyawan Swasta',
                rate: 4.5,
                review:
                  'Xooply menyediakan fitur yang memudahkan user untuk melakukan transaksi dan pembelian.',
              },
              {
                name: 'Alisya Shiva Itjerenni',
                occupation: 'Karyawan Swasta',
                rate: 4,
                review: 'Xooply mudah digunakan dan diaplikasikan bahkan untuk pengguna baru.',
              },
              {
                name: 'Friska Arifiani',
                occupation: 'Karyawan Swasta',
                rate: 4,
                review:
                  'Terdapat notifikasi web sehingga informasi didalamnya mengenai status transaksi sangat jelas.',
              },
              {
                name: 'Muhamad Nabil Ihsani',
                occupation: 'Karyawan Swasta',
                rate: 4,
                review:
                  'Status transaksi cukup mudah dipahami karena terdapat semua detail status pesanan yang dibutuhkan.',
              },
              {
                name: 'Marsya Ilina',
                occupation: 'Karyawan Swasta',
                rate: 4.5,
                review:
                  'Saya sangat puas bertransaksi di xooply karena mudah dalam pengaplikasiannya dan kategori produk jelas dapat dimengerti.',
              },
              {
                name: 'Nabila Tiara Putri',
                occupation: 'Karyawan Swasta',
                rate: 5,
                review:
                  'Menurut saya platform xooply memberikan tampilan yang memudahkan transaksi.',
              },
              {
                name: 'Art Fabiozie',
                occupation: 'Karyawan Swasta',
                rate: 5,
                review: 'Proses barang dikemas dan dikirim sangat cepat.',
              },
            ],
          },
	  COLLECTION_PAGE: {
            HOT_PRODUCT: {
              hero_banner: {
                content: [
                  {
                    image: {
                      path: 'https://storage.googleapis.com/xooply-assets/cp/hot-product/hot-product-banner.png',
                    },
                    redirectUrl: '',
                    sortIndex: 0,
                  },
                ],
              },
              priceStockIds: [
                '144670',
                '141906',
                '141107',
                '141108',
                '141109',
                '141110',
                '142434',
                '142437',
                '141196',
                '141190',
                '141182',
                '141192',
                '141189',
                '141174',
                '141167',
                '141159',
                '141152',
                '141151',
                '145956',
                '145957',
                '143548',
                '141092',
                '141823',
                '141169',
                '145087',
              ],
              breadcrumb: {
                pages: [{ name: 'Koleksi Hot Product Terlengkap & Terbaik', href: '/cp/hot-product' }],
              },
            },
          },
        },
      },
    },
    SEO: {
      CUSTOM_SEO_CATEGORY: true,
      CUSTOM_SEO_PRODUCT: true,
      MAINSITE: {
	      TITLE: 'eProcurement | Xooply',
      },
      SELLERSITE: {
        TITLE: 'eProcurement | Xooply',
      },
    },
    OPEN_GRAPH: {
      IMAGE: 'https://storage.googleapis.com/xooply-assets/og-xooply.png',
      TWITTER: {
        handle: '@xooply.id',
        site: '@xooply.id',
        cardType: 'summary_large_image',
      },
    },
  },
  nextConfig: {
    publicRuntimeConfig: {},
    images: {
      domains: [
        'api.newxooply-dev.id',
        's.newxooply.id',
        's.newxooply-dev.id',
        'xooply-s-dev.codexpertindo.com',
        'xooply-api-dev.codexpertindo.com',
        'api.xooply.id',
        'static.xooply.id',
        'temp.xooply.id',
        'storage.googleapis.com',
      ],
    },
  },
  tailwindcss: {
    colorTheme: {
      primary: {
        main: '#E41B1B',
        lighter: '#EC5F5F',
        darker: '',
      },
      secondary: {
        main: '',
        lighter: '',
        darker: '',
      },
    },
  },
};
