import getConfig from 'next/config';
import globalConfig from '../../../../global-config';

export const CONSTANTS = '';
const { publicRuntimeConfig } = getConfig();

export const ENV = {
  BASE_API_URL: publicRuntimeConfig.BASE_API_URL,
  BASE_URL: publicRuntimeConfig.BASE_URL,
  SELLER_URL: publicRuntimeConfig.SELLER_URL,
  SECRET: publicRuntimeConfig.SECRET,
  GOOGLE_MAP_API_KEY: publicRuntimeConfig.GOOGLE_MAP_API_KEY,
  GA_TRACKING_ID: publicRuntimeConfig.GA_TRACKING_ID,
  GTM_TRACKING_ID: publicRuntimeConfig.GTM_TRACKING_ID,
  GTAG_PURCHASING_ID: publicRuntimeConfig.GTAG_PURCHASING_ID,
};

export const KEY_STORAGE = {
  DETAIL_USER: 'DETAIL_USER',
  BUDGET_LIST_CHILD_COMPANY: 'BUDGET_LIST_CHILD_COMPANY',
  BUDGET_INFO_CHILD_COMPANY: 'BUDGET_INFO_CHILD_COMPANY',
  BUDGET_STAFF_LIST: 'BUDGET_STAFF_LIST',
  BUDGET_STAFF_HISTORY: 'BUDGET_STAFF_HISTORY',
  QUOTATION_LIST_DETAIL: 'QUOTATION_LIST_DETAIL',
  QUOTATION_SPH_DETAIL: 'QUOTATION_SPH_DETAIL',
};

export const USER_ROLE = {
  ADMIN: 'ADMIN',
  BUYER_ADMIN: 'BUYER_ADMIN',
  BUYER_MANAGER: 'BUYER_MANAGER',
  BUYER_STAFF: 'BUYER_STAFF',
  SELLER_ADMIN: 'SELLER_ADMIN',
  SELLER_CENTRAL: 'SELLER_CENTRAL',
  SELLER_BRANCH: 'SELLER_BRANCH',
  CUSTOMER: 'CUSTOMER',
};

export const getNameFromRole = (role) => {
  switch (role) {
    case USER_ROLE.BUYER_ADMIN:
      return 'Admin';
    case USER_ROLE.BUYER_MANAGER:
      return 'Manager';
    case USER_ROLE.BUYER_STAFF:
      return 'Staff';
    default:
      return role;
  }
};

export const PATHNAME_RECOVERY_PASSWORD = '/new-password?token=:token';

export const STATUS_PURCHASE = {
  NEED_APPROVAL: 'Menunggu Persetujuan',
  REVISION: 'Revisi',
  APPROVED: 'Disetujui',
  REJECTED: 'Ditolak',
  VERIFIED: 'Pembayaran Terverifikasi',
  FAILURE: 'Pembayaran Gagal',
};

export const STATUS_PURCHASE_INVOICE = {
  PAID: 'Dibayar',
  WAITING_PAYMENT: 'Menunggu Pembayaran',
  PAYMENT_VERIFIED: 'Pembayaran Terverifikasi',
  ON_GOING: 'Berlangsung',
};

export const STATUS_PURCHASE_INVOICE_WITH_COLOR = [
  {
    code: 'ON_GOING',
    name: 'Berlangsung',
    color: '#f97316',
  },
  {
    code: 'WAITING_PAYMENT',
    name: 'Menunggu Pembayaran',
    color: '#ef4444',
  },
  {
    code: 'PAID',
    name: 'Dibayar',
    color: '#219653',
  },
  {
    code: 'PAYMENT_VERIFIED',
    name: 'Pembayaran Terverifikasi',
    color: '#219653',
  },
];

export const STATUS_PO = {
  CREATED: 'Pesanan Dibuat',
  APPROVED: 'Menunggu Respon Penjual',
  REJECTED: 'Ditolak Penjual',
  PACKED: 'Diproses Penjual',
  SHIPPED: 'Dikirim',
  READY_TO_PICKUP: 'Siap Diambil',
  PARTIAL_RECEIVED: 'Diterima Sebagian',
  RECEIVED: 'Diterima',
  WAITING_PAYMENT: 'Menunggu Pembayaran',
  PAYMENT_RECEIVED: 'Dibayar',
  PAID: 'Pembayaran Terverifikasi',
  FAILURE: 'Pembayaran Kedaluwarsa',
  DONE: 'Selesai',
};

export const STATUS_PO_COLOR = [
  {
    name: 'Semua',
    code: '',
  },
  {
    name: 'Menunggu Pembayaran',
    code: 'WAITING_PAYMENT',
    color: '#EF801B',
  },
  {
    name: 'Dibayar',
    code: 'PAYMENT_RECEIVED',
    color: '#FFCA06',
  },
  {
    name: 'Pesanan Dibuat',
    code: 'CREATED',
    color: '#3066D2',
  },
  {
    name: 'Diproses Penjual',
    code: 'PACKED',
    color: '#003D79',
  },
  {
    name: 'Ditolak Penjual',
    code: 'REJECTED',
    color: '#EC2626',
  },
  {
    name: 'Dikirim',
    code: 'SHIPPED',
    color: '#2F80ED',
  },
  !!globalConfig?.PICKUP_AT_STORE && {
    name: 'Siap Diambil',
    code: 'READY_TO_PICKUP',
    color: '#00A925',
  },
  {
    name: 'Diterima',
    code: 'RECEIVED',
    color: '#00A925',
  },
  {
    name: 'Diterima Sebagian',
    code: 'PARTIAL_RECEIVED',
    color: '#0000004d',
  },
  {
    name: 'Pembayaran Terverifikasi',
    code: 'PAID',
    color: '#FFCA06',
  },
  {
    name: 'Pembayaran Kedaluwarsa',
    code: 'FAILURE',
    color: '#EC2626',
  },
  {
    name: 'Selesai',
    code: 'DONE',
    color: '#00A925',
  },
]?.filter((status) => !!status);

export const STATUS_PO_RETAIL_COLOR = [
  {
    name: 'Menunggu Pembayaran',
    color: '#EF801B',
  },
  {
    name: 'Menunggu Konfirmasi Penjual',
    color: '#3066D2',
  },
  {
    name: 'Order Diproses Penjual',
    color: '#003D79',
  },
  {
    name: 'Order Dikirim',
    color: '#2F80ED',
  },
  {
    name: 'Selesai',
    color: '#00A925',
  },
  {
    name: 'Order Ditolak Penjual',
    color: '#EC2626',
  },
  {
    name: 'Order Kadaluarsa',
    color: '#EC2626',
  },
];

export const STATUS_QUOTATION_COLOR = [
  {
    name: 'Selesai',
    code: 'DONE',
    color: '#3066D2',
  },
  {
    name: 'Pengajuan Baru',
    code: 'REQUESTED',
    color: '#EF801B',
  },
  {
    name: 'SPH Diajukan',
    code: 'QUOTATION_SUBMITED',
    color: '#219653',
  },
  {
    name: 'Permintaan Kedaluwarsa',
    code: 'EXPIRED',
    color: '#E41B1B',
  },
];

export const STATUS_VA_PO = {
  PENDING: 'Menunggu Pembayaran',
  SETTLEMENT: 'Pembayaran Terverifikasi',
  EXPIRED: 'Pembayaran Kedaluwarsa',
};

export const MAPPING_PAYMENT_TYPE = [
  {
    code: 'budget',
    name: 'Anggaran',
  },
  {
    code: 'mandiri_va',
    name: 'Mandiri Virtual Account',
  },
  {
    code: 'bca_va',
    name: 'BCA Virtual Account',
  },
  {
    code: 'bni_va',
    name: 'BNI Virtual Account',
  },
  {
    code: 'bri_va',
    name: 'BRI Virtual Account',
  },
  {
    code: 'qris',
    name: 'QRIS',
  },
  {
    code: 'tempo_va_14d_mandiri',
    name: 'Tempo 14 hari - VA Mandiri',
  },
  {
    code: 'tempo_va_30d_mandiri',
    name: 'Tempo 30 hari - VA Mandiri',
  },
  {
    code: 'tempo_va_60d_mandiri',
    name: 'Tempo 60 hari - VA Mandiri',
  },
  {
    code: 'paylater_kredivo',
    name: 'Kredivo',
  },
  {
    code: 'ewallet_linkaja',
    name: 'LinkAja',
  },
  {
    code: 'credit_card',
    name: 'Credit Card',
  },
];

export const { CONFIG_SITE } = globalConfig;
