/* eslint-disable arrow-body-style */
import 'antd/dist/reset.css';
import '@utils/ui/base.scss';
import { useEffect, useMemo } from 'react';
import { QueryClient, QueryClientProvider, Hydrate } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { appWithTranslation } from 'next-i18next';
import { DefaultSeo } from 'next-seo';
import { AuthProvider } from 'utils/context/auth';
import { ConfigProvider } from 'antd';
import numeral from 'numeral';
import { Toaster } from 'react-hot-toast';
import Script from 'next/script';
import { SEO } from '../../next-seo.config';
import { GA_TRACKING_ID, GTM_TRACKING_ID } from 'utils/gtag.utils';
import Head from 'next/head';
import App from 'next/app';

import 'react-loading-skeleton/dist/skeleton.css';
import 'moment/locale/id';
import 'dayjs/locale/id';
import dayjs from 'dayjs';

dayjs.locale('id');

const MyApp = (props) => {
  const {
    Component,
    pageProps,
    props: { defaultFullUrl },
  } = props;

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: true,
        refetchOnmount: true,
        staleTime: 5 * 60 * 1000,
        retry: 3,
      },
    },
  });

  useEffect(() => {
    if (numeral.locales.id === undefined) {
      numeral.register('locale', 'id', {
        delimiters: {
          thousands: '.',
          decimal: ',',
        },
        abbreviations: {
          thousand: 'rb',
          million: 'jt',
          billion: 'm',
          trillion: 't',
        },
        ordinal() {
          return '.';
        },
        currency: {
          symbol: 'Rp.',
        },
      });
      numeral.locale('id');
    }
  }, []);

  const SEOObject = useMemo(
    () => ({
      ...SEO,
      canonical: defaultFullUrl,
      openGraph: { ...SEO.openGraph, url: defaultFullUrl },
    }),
    [defaultFullUrl],
  );

  const appENV = process?.env?.NEXT_PUBLIC_APP_ENV ?? 'production';
  const enableDebugGA = ['staging', 'development'].includes(appENV);

  useEffect(() => {
    console.info('process?.env?.NODE_ENV', appENV);
  }, [appENV]);

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width, user-scalable=no" />
      </Head>
      {/* GA - Google Analytics */}
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />
      <Script
        id="ga4-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${GA_TRACKING_ID}', { 'debug_mode': ${
                enableDebugGA ? 'true' : 'false'
              } });
              `,
        }}
      />

      {/* GTM - Google Tag Manager */}
      <Script
        id="gtm-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${GTM_TRACKING_ID}');
            `,
        }}
      />
      <ConfigProvider
        theme={{
          token: {
            fontFamily: 'Poppins',
          },
        }}
      >
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <DefaultSeo {...SEOObject} />
            <AuthProvider>
              <Component {...pageProps} />
              <Toaster
                position="top-center"
                reverseOrder={false}
                toastOptions={{ style: { maxWidth: 500 } }}
              />
            </AuthProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </Hydrate>
        </QueryClientProvider>
      </ConfigProvider>
    </>
  );
};

MyApp.getInitialProps = async (context) => {
  const ctx = await App.getInitialProps(context);

  const defaultAsPath = context?.ctx?.asPath || '';

  return {
    ...ctx,
    props: {
      defaultAsPath,
      defaultFullUrl: process.env.BASE_URL + defaultAsPath,
    },
  };
};

export default appWithTranslation(MyApp);
